<script setup lang="ts">
import playermonAbstract from '@/assets/data/playermon.json'

interface PlayermonSelectedProps {
  playermonSelected: IPlayermon
}

const componentRoute = useRoute()

const props = defineProps<PlayermonSelectedProps>()

const groupedAttributes = computed(() => {
  const group: Record<string, { d?: PlayermonAttributes; r1?: PlayermonAttributes; r2?: PlayermonAttributes }> = {}

  props.playermonSelected.attributes.forEach((attr) => {
    // Skip "rarity" and "class"
    if (attr.trait_type === 'rarity' || attr.trait_type === 'class') {
      return
    }

    const traitBase = attr.trait_type.replace(/_r[12]$/, '')

    if (!group[traitBase]) {
      group[traitBase] = {}
    }

    if (attr.trait_type.endsWith('_r1')) {
      group[traitBase].r1 = attr
    } else if (attr.trait_type.endsWith('_r2')) {
      group[traitBase].r2 = attr
    } else {
      group[traitBase].d = attr
    }
  })

  return group
})

const classMapping = (playermonClass: string): string => {
  const lowerCasePlayermonClass = playermonClass.toLowerCase()
  switch (lowerCasePlayermonClass) {
    case 'cosmos':
    case 'constellation':
    case 'pegasus':
    case 'ursa':
    case 'wish':
    case 'eclipse':
      return 'comet'

    case 'wharfe':
    case 'nautilus':
    case 'razortooth':
    case 'kraken':
    case 'clamhorn':
    case 'umi':
      return 'aqua'

    case 'gaia':
    case 'petunia':
    case 'moonmoth':
    case 'crawleaf':
    case 'venus':
    case 'ivy':
      return 'nature'

    case 'igneous':
    case 'dynamite':
    case 'wick':
    case 'phoenix':
    case 'hollow':
    case 'cinders':
      return 'inferno'

    case 'artificer':
    case 'developer':
    case 'artillery':
    case 'ingenium':
    case 'phantacle':
    case 'clockwork':
      return 'mech'

    case 'juggler':
    case 'ankh':
    case 'hermes':
    case 'shinu':
    case 'doktha':
    case 'glitter':
      return 'mythos'

    default:
      return ''
  }
}

const classColor = (classTrait: string): string => {
  const family = playermonAbstract.find(
    playermonFamily => playermonFamily.family.toLowerCase() === classTrait.toLowerCase() ||
    playermonFamily.members.some(member => member.toLowerCase() === classTrait.toLowerCase())
  )
  return family ? family.bgSecondaryColor : ''
}

const getTraitClass = (traitValue: string): string => {
  const family = playermonAbstract.find(
    playermonFamily => playermonFamily.members.some(member => member.toLowerCase() === traitValue.toLowerCase())
  )
  return family ? family.family : ''
}
</script>

<template>
  <div>
    <div v-if="componentRoute.path !== '/marketplace'" class="flex flex-col items-start justify-center mt-2 md:gap-2">
      <p class="py-2 text-sm font-bold uppercase md:text-xl text-smoke-grey">
        Recessive Genes
      </p>

      <div>
        <!-- Table Header -->
        <div class="grid grid-cols-3 gap-4 my-2">
          <p class="col-start-1 font-bold text-center text-smoke-grey">
            D
          </p>
          <p class="col-start-2 font-bold text-center text-smoke-grey">
            R1
          </p>
          <p class="col-start-3 font-bold text-center text-smoke-grey">
            R2
          </p>
        </div>

        <!-- Table Content -->
        <div v-for="(attributes, trait) in groupedAttributes" :key="trait" class="grid grid-cols-3 gap-4 mb-1 text-xs md:text-base">
          <div class="flex flex-row pr-2 md:border-r md:gap-2 border-white/30">
            <img
              class="h-5 mr-2"
              :src="`https://download.playermon.com/img/playermonpart/${classMapping(attributes.d?.value || '')}_${trait}.png`"
            >
            <p :style="{ color: classColor(getTraitClass(attributes.d?.value || '')) }">
              {{ attributes.d?.value || '-' }}
            </p>
          </div>
          <div class="flex flex-row pr-2 md:border-r md:gap-2 border-white/30">
            <img
              class="h-5 mr-2"
              :src="`https://download.playermon.com/img/playermonpart/${classMapping(attributes.r1?.value || '')}_${trait}.png`"
            >
            <p :style="{ color: classColor(getTraitClass(attributes.r1?.value || '')) }">
              {{ attributes.r1?.value || '-' }}
            </p>
          </div>
          <div class="flex flex-row">
            <img
              class="h-5 mr-2"
              :src="`https://download.playermon.com/img/playermonpart/${classMapping(attributes.r2?.value || '')}_${trait}.png`"
            >
            <p :style="{ color: classColor(getTraitClass(attributes.r2?.value || '')) }">
              {{ attributes.r2?.value || '-' }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="componentRoute.path === '/marketplace'" class="flex flex-col items-start justify-center md:gap-2">
      <div>
        <!-- Table Header -->
        <div class="grid grid-cols-3 gap-4 my-2 text-xs">
          <p class="col-start-1 font-bold text-center text-smoke-grey">
            D
          </p>
          <p class="col-start-2 font-bold text-center text-smoke-grey">
            R1
          </p>
          <p class="col-start-3 font-bold text-center text-smoke-grey">
            R2
          </p>
        </div>

        <!-- Table Content -->
        <div v-for="(attributes, trait) in groupedAttributes" :key="trait" class="grid grid-cols-3 gap-4 mb-1 text-xs">
          <div class="flex flex-row pr-2 border-white/30">
            <img
              class="h-4 mr-2"
              :src="`https://download.playermon.com/img/playermonpart/${classMapping(attributes.d?.value || '')}_${trait}.png`"
            >
            <p :style="{ color: classColor(getTraitClass(attributes.d?.value || '')) }">
              {{ attributes.d?.value || '-' }}
            </p>
          </div>
          <div class="flex flex-row pr-2 md:gap-2 border-white/30">
            <img
              class="h-4 mr-2"
              :src="`https://download.playermon.com/img/playermonpart/${classMapping(attributes.r1?.value || '')}_${trait}.png`"
            >
            <p :style="{ color: classColor(getTraitClass(attributes.r1?.value || '')) }">
              {{ attributes.r1?.value || '-' }}
            </p>
          </div>
          <div class="flex flex-row md:gap-2">
            <img
              class="h-4 mr-2"
              :src="`https://download.playermon.com/img/playermonpart/${classMapping(attributes.r2?.value || '')}_${trait}.png`"
            >
            <p :style="{ color: classColor(getTraitClass(attributes.r2?.value || '')) }">
              {{ attributes.r2?.value || '-' }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
